$background-color: #ffffff;
$font-blue-color: #4785FF;
$text-bold-color: var(--grey-500-default, #0c122d);
$accent: #0093ff;
$green-accent-500: #00ff7e;
$grey-400: #657085;
$grey-500: #0c122d;
$blue-semantic-info-600: #002df9;

$font-family-name: 'TTNorms';
$font-light-path: './assets/fonts/TTNorms-Light.otf';
$font-regular-path: './assets/fonts/TTNorms-Regular.otf';
$font-normal-500-path: './assets/fonts/TTNorms-Medium.otf';
$font-normal-bold-path: './assets/fonts/TTNorms-Bold.otf';

@font-face {
  font-family: $font-family-name;
  src: url($font-light-path) format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: $font-family-name;
  src: url($font-regular-path) format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: $font-family-name;
  src: url($font-normal-500-path) format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: $font-family-name;
  src: url($font-normal-bold-path) format("opentype");
  font-weight: bold;
  font-style: normal;
}

%uppercase {
  text-transform: uppercase;
  letter-spacing: 0.3em;
}
