html {
  background-color: $background-color;
  font-size: 75.5%; // 65.5
  margin: 0;
  padding: 0;
  height: 100%;
  @include respond(tab-land) {
    font-size: 56.25%;
  }
  @include respond(tab-port) {
    font-size: 50%;
  }
  @include respond(phone) {
    font-size: 45%;
  }
  @include respond(big-desktop) {
    font-size: 75%;
  }
}

body {
  margin: 0;
  padding: 0;
  // height: 100%;
  font-family:
    $font-family-name,
    sans-serif,
    -apple-system,
    BlinkMacSystemFont;
  box-sizing: border-box;
  // background: linear-gradient(to bottom, #002df9 20%, white 0%);
  // background: linear-gradient(to bottom, $blue-semantic-info-600 60vh, white 0vh);
  // background: linear-gradient(to bottom, $blue-semantic-info-600 72vh, white 0%);
  background-size: cover;
  letter-spacing: 0.045rem;
  display: flex;
  flex-direction: column;
  @media (max-width: 1024px) {
    @media (min-height: 1366px) {
      // top: calc(100% - 85vh);
      // background: linear-gradient(to bottom, $blue-semantic-info-600 45vh, white 0vh);
    }
  }
}

h1 {
  font-size: 32px;
  color: $font-blue-color;
  font-weight: 500;
  line-height: 100%;
  margin: 0;
}

h2 {
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-size: 24px;
  color: $font-blue-color;
  font-weight: bold;
  line-height: 32px; /* 133.333% */
  margin: 0;
}

h3 {
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-size: 20px;
  color: $font-blue-color;
  font-weight: 500;
  line-height: 32px; /* 133.333% */
  margin: 0;
}

h6 {
  margin: 0;
  padding: 0;
  font-size: 15px;
}

p {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 160% */
}

.header-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 2rem;
  background-color: $blue-semantic-info-600;
}


// Spinner style
.custom-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.5rem;
  height: 4.5rem;
  animation: spin-dash 1s linear infinite;
  // animation:
  //   spin-dash 1.2s ease-in-out infinite,
  //   spinner-color 6s ease-in-out infinite;
}

@keyframes spin-dash {
  0% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(90deg);
  }
  70% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
// ----End Spinner----
